import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import DatePicker from '../DatePicker'
import styles from './styles.module.css'
import { Box, Card, CardBody, Center,  Text } from '@chakra-ui/react'


function formatDate(date, pickerType) {
    const year = date.getFullYear().toString().substr(-2);
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hour = date.getHours().toString().padStart(2, '0');
    const minute = '00';

    const weekdays = ['일', '월', '화', '수', '목', '금', '토'];
    const weekday = weekdays[date.getDay()];

    return pickerType === 'time'
        ? `${year}.${month}.${day} ${weekday} ${hour}:${minute}`
        : pickerType === 'days'
        ? `${year}.${month}.${day} ${weekday}`
        : `${year}년 ${month}월`;
}
const Input = (props) => {
    const inputEl = useRef(null)
    const [open, setOpen] = useState(false)
    useEffect(() => {
        document.addEventListener('click', _onDocumentClick)
        return () => {
            document.removeEventListener('click', _onDocumentClick)
        }
    }, [open])
    const _onDocumentClick = (e) => {
        if (open && !inputEl.current.contains(e.target)) {
            closePopup()
        }
    }
    const closePopup = () => {
        setOpen(false)
        props.handleDateChange(props.value)
    }
    const { value, pickerType } = props
    const onChange = (date) => {
        if (typeof props.onChange === 'function') props.onChange(date)
        // alert(date)
    }
    const popupClass = cx(styles.PopUpWrapper, {
        [styles.open]: open
    })

    return (
        <Box w='100%' ref={inputEl} p='0' mb='-1.5rem'>
            <Card w='15.8125rem' h='2.5rem' p='0' boxShadow={'0px 1px 4px 0px rgba(0, 0, 0, 0.25)'} onClick={() => setOpen(true)}>
                <CardBody p='0' h='2.5rem' >
                    <Center w='100%' h='100%' >
                        <Text fontWeight='500' fontSize='1rem' fontFamily='Pretendard-Medium'>
                            {formatDate(props.selectedDate, props.pickerType)}
                        </Text>
                    </Center>
                    <Box className={styles.svgWrapper} h='2.125rem' w='2.125rem' >
                        <FontAwesomeIcon icon={faChevronDown} size='lg' />
                    </Box>
                </CardBody>
            </Card>
            <Box className={styles.InputWrapper} >
                <Box className={popupClass}>
                    <DatePicker value={value} onSelect={onChange} pickerType={pickerType} closeFunction={closePopup} />
                </Box>
            </Box>
        </Box>
    )
}
export default React.memo(Input)
Input.propTypes = {
    value: PropTypes.instanceOf(Date).isRequired,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    pickerType: PropTypes.string
}
Input.defautProps = {
    pickerType: "days",
}
