import { Box, Image, Text } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";

export const TopNavBar = () => {
    return (
        <Box  h='3rem'
            ml='0.8rem'
            // padding="0.25rem 0.5rem 0.25rem 1rem"
            display="flex" alignItems="center"
        >
            <Link to='/'>
                {/* <Text
                    justifyContent='center'
                    fontFamily='Goldman' fontSize='2rem'
                    letterSpacing='-0.125rem'
                    fontWeight='700'
                    bgClip='text'
                    bgGradient='linear-gradient(180deg, #1F3F72 68.75%, rgba(31, 63, 114, 0.00) 100%)'
                    _webkitBackgroundClip="text"
                    color='transparent'
                >HERO CHART


                </Text> */}
                <Image
                    w='12rem'
                    h='1.2rem'
                    src="/page_title.svg" />
            </Link>
            {/* <Image src="/hero_chart.jpeg"/ > */}
        </Box>
    );
};