import React from 'react';

import { MainPage } from '../components/MainPage';


function Global() {
    const activeMenu = 5;
    const siteMenus = ["써클", "스포티파이", "빌보드"];
    const chartMap = {
        "써클": ["써클글로벌 일간차트", "써클글로벌 주간차트"],
        "스포티파이": ["스포티파이 일간차트", "스포티파이 주간차트"],
        "빌보드": ["빌보드 글로벌(-美) 주간차트", "빌보드 로컬 주간차트"]
    }

    return (
        <MainPage siteMenus={siteMenus} chartMap={chartMap} activeMenu={activeMenu} pickerType="days"/>
    );
}

export default Global;
