import { AspectRatio, Box, Text, Center, VStack } from "@chakra-ui/react";
import React from "react";


const YoutubeMV = () => {
    return (
        <Box w="100%" pt='1.69rem'>
            <VStack align='stretch' spacing='0'>
                <Center>
                    <Box minW="20.5625rem" maxW="21.5625rem" mb='0.56rem'>
                        <Text
                            fontSize='1rem' fontFamily='Pretendard-Black' lineHeight='1.5rem'
                            bgGradient="linear-gradient(90deg, #1E3C72 0%, #1E3C72 1%, #2A5298 100%)"
                            bgClip="text"
                            // _webkitBackgroundClip="text"
                            color="transparent"
                        >
                            M/V
                        </Text>
                    </Box>
                </Center>
                <Center>
                    <AspectRatio minW="20.5625rem" maxW="21.5625rem" w="100%" ratio={22.5625 / 13.3125} mb='1.37rem'>
                        <iframe
                            title='naruto'
                            src='https://www.youtube.com/embed/r9yPek7FhSQ'
                            allowFullScreen
                        />
                    </AspectRatio>
                </Center>
                <Center>
                    <Box minW="20.5625rem" maxW="21.5625rem" borderTop='solid 0.00625rem #BCBCBC' />
                </Center>
            </VStack>
        </Box>
    )
}

export default YoutubeMV;