import React, { useContext } from 'react';
import { SongsContext } from './SongsContext';

import { Box, Grid, GridItem, Text, Image, Flex, Card, CardBody, VStack, Center, Link } from '@chakra-ui/react';

function base64EncodeAndReplace(input) {
    // Encode the string to Base64
    let encodedString = btoa(unescape(encodeURIComponent(input)));
    // Replace '/' with '_' in the encoded string
    let replacedString = encodedString.replace(/\//g, '_');
    return replacedString;
}

export const RankRow = ({ songTitle, rank, rankVariation, isNew, isMelon, isYoutube, additionalInfo, siteChartName }) => {
    const songs = useContext(SongsContext);

    let titleKey = songTitle.toUpperCase().replace(/ /g, '');
    if (titleKey === "폴라로이드") {
        titleKey = "POLAROID";
        songTitle = "POLAROID";
    }

    let ytMusicLink = "";
    let ytMVLink = "";
    // check songs has titleKey 
    if (titleKey in songs) {
        ytMusicLink = songs[titleKey].yt_music_link;
        ytMVLink = songs[titleKey].yt_mv_link;
    }

    const handleAppLinkClick = (e) => {
        e.preventDefault();
        const appUrl = `melonapp://play?ctype=1&menuid=1000000026&cid=${songs[titleKey].melon_id}`;
        const webUrl = `https://www.melon.com/song/detail.htm?songId=${songs[titleKey].melon_id}`;

        // 앱으로 이동 시도
        window.location = appUrl;

        // 2초 후에 웹 URL로 리디렉션 시도
        setTimeout(() => {
            window.open(webUrl, '_blank');
        }, 2000);
    };


    let variationImage = 'no_change.png';

    if (rankVariation === null || rankVariation === undefined) {
        rankVariation = 0;
    }

    if (rankVariation > 0) {
        variationImage = 'up.svg';
    } else if (rankVariation < 0) {
        variationImage = 'down.svg';
    }

    let verboseString = "";
    const numberWithCommas = (num) => {
        // if num is nan, return -
        if (isNaN(num)) {
            return '-';
        }
        return num.toLocaleString();
    }
    if (Object.keys(additionalInfo).length !== 0) {

        if (additionalInfo.pcount2) { // 멜론
            verboseString = `1H: ${numberWithCommas(parseInt(additionalInfo.pcount))}  |  24H: ${numberWithCommas(parseInt(additionalInfo.pcount2))}`;
        } else if (additionalInfo.pcount1) { // 멜론 일간 or 써클 디지털 주간
            verboseString = numberWithCommas(parseInt(additionalInfo.pcount));
        } else if (additionalInfo.streams) {
            verboseString = numberWithCommas(parseInt(additionalInfo.streams));
        } else if (additionalInfo.hit_ratio) {
            verboseString = numberWithCommas(parseInt(additionalInfo.hit_ratio));
        } else {
            verboseString = "";
        }
    }

    // https://cdn.hero-chart.kr/{title}.jpg
    const thumbnailTitle = songTitle.toUpperCase().replace(/ /g, '');

    // base64 encoding
    const encodedFileName = base64EncodeAndReplace(thumbnailTitle);
    let thumbnailURL = `https://cdn.hero-chart.kr/images_new/${encodedFileName}.jpg`;
    if (siteChartName === "유튜브 뮤비 주간차트") {
        thumbnailURL = `https://i.ytimg.com/vi/${additionalInfo.youtube_id}/default.jpg`;
        ytMVLink = `https://youtu.be/${additionalInfo.youtube_id}`;
        console.log(thumbnailURL, siteChartName);
    }

    return (
        <Card
            mb='0.25rem' mt='0.25rem' minW="20.5625rem" maxW="21.5625rem" h="3.75rem"
            // bg='background: linear-gradient(90deg, #DFE9F3 0%, #FFF 100%)'
            boxShadow='0px 1px 4px 0px rgba(0, 0, 0, 0.25)'
        >
            <CardBody p='0' h='3.75rem'>
                {/* <Grid templateColumns={'3.2rem 0.7rem 5.2rem auto'} gap='0' alignItems='center' h='3.75rem'> */}
                <Grid templateColumns={'3.2rem 3.2rem 10.8rem auto 0.6rem'} gap='0' alignItems='center' h='3.75rem'>

                    <GridItem w='100%' colSpan={1} ml='0.125rem' >
                        <Flex alignItems="center" justifyContent={"flex-end"}>
                            <Image src={thumbnailURL} rounded={"4px"} w='2.8125rem' h='2.8125rem' />
                        </Flex>
                    </GridItem>

                    <GridItem w='100%' colSpan={1} ml="0.2rem">
                        <Center h="2.8125rem">
                            <VStack h="2.8125rem" spacing={0}>
                                <Box h="1.6rem">
                                    <Text
                                        fontWeight={'700'} fontSize='1.25rem' fontFamily="Pretendard-Bold">{rank}
                                    </Text>
                                </Box>
                                <Box h="1.2rem">
                                    <Flex alignItems="center" h="100%">
                                        {isNew && <Text fontSize='0.625rem' fontWeight='800' color='#90CBF9'>NEW</Text>}
                                        {!isNew && (<Image src={`/${variationImage}`} w="0.55rem" />)}
                                        {(!isNew && rankVariation !== 0) && (
                                            <Text
                                                ml='0.1rem'
                                                fontSize='0.75rem'
                                                fontWeight='400'
                                                color={rankVariation > 0 ? '#2DBE9A' : '#F38D7F'}
                                            >
                                                {Math.abs(rankVariation)}
                                            </Text>
                                        )}
                                        <Text> </Text>
                                    </Flex>
                                </Box>
                            </VStack>
                        </Center>
                    </GridItem>
                    {
                        verboseString === "" && (
                            <GridItem ml="0.28rem" w='100%' colSpan={1}><Text fontSize='0.8725rem' fontWeight='400'>{songTitle}</Text></GridItem>
                        )
                    }
                    {
                        verboseString && (
                            <GridItem w='100%' colSpan={1}>
                                <Box>
                                    <Text ml="0.28rem" fontSize='0.8725rem' fontWeight='400'>{songTitle}</Text>
                                    <Text ml="0.28rem" fontSize='0.75rem' fontWeight='400' color='#979797'>{verboseString}</Text>
                                </Box>
                            </GridItem>
                        )
                    }
                    <GridItem w='100%' colSpan={1} >
                        <Flex>
                            {isMelon && (
                                <>
                                    <Box w="1.75rem"></Box>
                                    <Box>
                                        <Link href="#" onClick={handleAppLinkClick}>
                                            <Box
                                                borderRadius="2rem"
                                                bg="#FFFFFF"
                                                boxShadow="0px 4px 4px 0px rgba(0, 0, 0, 0.15)"
                                                padding="0"
                                                w="1.75rem"
                                                h="1.75rem"
                                            >
                                                <Flex
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    w="100%"
                                                    h="100%"
                                                >
                                                    <Box>
                                                        <Image src={`/melon_play.png`} w="1.2rem" h="1.2rem" />
                                                    </Box>
                                                </Flex>
                                            </Box>
                                        </Link>
                                    </Box>
                                </>
                            )}
                            {isYoutube && (
                                <>
                                    {ytMusicLink ? <Box>
                                        <Link href={ytMusicLink} isExternal>
                                            <Box
                                                borderRadius="2rem"
                                                bg="#FFFFFF"
                                                boxShadow="0px 4px 4px 0px rgba(0, 0, 0, 0.15)"
                                                padding="0"
                                                w="1.75rem"
                                                h="1.75rem"
                                            >
                                                <Flex
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    w="100%"
                                                    h="100%"
                                                >
                                                    <Box>
                                                        <Image src={`/youtube_music.png`} w="1.1875rem" h="1.1875rem" />
                                                    </Box>
                                                </Flex>
                                            </Box>
                                        </Link>
                                    </Box> : <Box w="1.75rem"></Box>}
                                    <Box ml="0.31rem">
                                        <Link href={ytMVLink} isExternal>
                                            <Box
                                                borderRadius="2rem"
                                                bg="#FFFFFF"
                                                boxShadow="0px 4px 4px 0px rgba(0, 0, 0, 0.15)"
                                                padding="0"
                                                w="1.75rem"
                                                h="1.75rem"
                                            >
                                                <Flex
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    w="100%"
                                                    h="100%"
                                                >
                                                    <Box>
                                                        <Image src={`/youtube_video.png`} w="1.1875rem" h="1.1875rem" />
                                                    </Box>
                                                </Flex>
                                            </Box>
                                        </Link>
                                    </Box>
                                </>
                            )}
                        </Flex>
                    </GridItem>

                </Grid>
            </CardBody>
        </Card>
    );
};
