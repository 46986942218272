import { useNavigate, useParams } from 'react-router-dom';
import { TopNavBar } from '../components/TopNavBar';

import React, { useEffect } from 'react';
import {
    Box, Center, Flex, Text, VStack, Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleLeft, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';


const MelonHistory = ({ match }) => {
    const navigate = useNavigate();
    const { songId } = useParams();
    const [song, setSong] = React.useState({});
    useEffect(() => {
        const minuteStr = new Date().toISOString().slice(0, 14).replace(/-/g, "").replace(/:/g, "");
        fetch(`https://cdn.hero-chart.kr/artist/hero/history/melon/${songId}.json?v=${minuteStr}`)
            .then(res => {
                if (!res.ok) {
                    throw Error("could not fetch the data that resource");
                  }
        
                return res.json()
            })
            .then(data => {
                setSong({ ...data, histories: data.histories.reverse() });
            })
            .catch(err => {
                console.log(err);
            })
    }, []);

    const backButtonClick = () => {
        navigate(-1);
    }

    const parseDate = (dateStr) => {
        return dateStr.split(" ")[0].replace(/-/g, ".");
    }

    const getWeekDay = (dateStr) => {
        const date = new Date(dateStr);
        const weekDay = date.getDay();
        return weekDay;
    }

    const numberWithCommas = (num) => {
        // if num is nan, return -
        if (isNaN(num)) {
            return '-';
        }
        return parseInt(num).toLocaleString();
    }

    return (
        <>
            <TopNavBar />
            <Box w="100%" pt='1.37rem' bg='linear-gradient(90deg, #FDFBFB 0%, #EBEDEE 100%)' minH='90vh'>
                <VStack spacing='0'>
                    <Box w="21.375rem" minW="20.5625rem" maxW="21.5625rem" mb='0.56rem'>
                        <Flex alignItems='center'>
                            <Box
                                onClick={backButtonClick}
                                bg='linear-gradient(90deg, #1E3C72 0%, #1E3C72 1%, #2A5298 100%);'
                                w="1.625rem"
                                h="1.625rem"
                                borderRadius="full"
                                display='flex'
                                justifyContent='center'
                                alignItems='center'
                            >
                                <FontAwesomeIcon icon={faChevronLeft} color='#fff' size='xs' />
                            </Box>
                            <Text
                                ml='0.5rem'
                                fontSize='1rem' fontFamily='Pretendard-Black' lineHeight='1.5rem'
                                bgGradient="linear-gradient(90deg, #1E3C72 0%, #1E3C72 1%, #2A5298 100%)"
                                bgClip="text"
                                color="transparent"
                            >
                                {song.title} - 멜론 일간
                            </Text>
                        </Flex>
                    </Box>

                    <Box w="21.375rem" mb='1rem'>
                        <TableContainer>
                            <Table
                                variant="unstyled" size="sm"
                                borderStyle="hidden"
                                borderRadius="0.5rem;"
                                border="1px solid #B9B9B9;"
                                borderCollapse="collapse;"
                            >

                                <Thead
                                    bg="rgba(0, 0, 0, 0.06);"
                                    border="1px solid #B9B9B9;"
                                    borderRadius="0.5rem;"
                                >
                                    <Tr>
                                        {
                                            ["DAY", "RANKING", "USERS"].map((title, index) => (
                                                <Th
                                                    key={index + "T"}
                                                    border="1px solid #B9B9B9;"
                                                    textAlign="center"
                                                    height="2.5rem"
                                                    fontFamily='Pretendard-SemiBold'
                                                    w="7.125rem"
                                                >
                                                    {title}
                                                </Th>
                                            ))
                                        }
                                    </Tr>
                                </Thead>
                                <Tbody
                                >
                                    {
                                        Object.keys(song).length !== 0 && (song.histories.map((history, index) => (
                                            <Tr key={index}
                                                bg="#FFF"
                                            >
                                                <Td
                                                    border="1px solid #B9B9B9;"
                                                    textAlign="center"
                                                    height="2.5rem"
                                                    fontSize="0.75rem"
                                                    color={getWeekDay(history.datetime) === 6? "#59A1CF": getWeekDay(history.datetime) === 0 ? "#EA5D7C" :"#000"}
                                                >
                                                    {parseDate(history.datetime)}
                                                </Td>
                                                <Td
                                                    border="1px solid #B9B9B9;"
                                                    textAlign="center"
                                                    height="2.5rem"
                                                    fontSize="0.75rem"
                                                    color={getWeekDay(history.datetime) === 6? "#59A1CF": getWeekDay(history.datetime) === 0 ? "#EA5D7C" :"#000"}
                                                >
                                                    {history.rank}위
                                                </Td>
                                                <Td
                                                    border="1px solid #B9B9B9;"
                                                    textAlign="center"
                                                    height="2.5rem"
                                                    fontSize="0.75rem"
                                                    color={getWeekDay(history.datetime) === 6? "#59A1CF": getWeekDay(history.datetime) === 0 ? "#EA5D7C" :"#000"}
                                                >
                                                    {
                                                        history.score ? numberWithCommas(history.score) :
                                                            history.additional_fields ? numberWithCommas(history.additional_fields.pcount) : "-"
                                                    }
                                                </Td>
                                            </Tr>
                                        ))
                                        )
                                    }
                                </Tbody>
                            </Table>
                        </TableContainer>
                    </Box>
                </VStack>
            </Box >
        </>
    );
}

export default MelonHistory;
