import { Box, Center, Link, Text, VStack } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import React from "react";

const Notice = () => {
    return (
        <Center w='100%'>
            <Link
                href='https://x.com/HeroChart0616?t=IjoawzzFyz4cPSD9iIU00g&s=32'
                // href='melonapp://play?ctype=1&menuid1000000026&&cid=36871671'
                isExternal
                _hover={{ "cursor": "pointer" }}
                display="flex"
                alignItems="center"
                justifyContent="center"
                w='100%'
                h='2.8125rem'
                // bg='linear-gradient(90deg, #E0C3FC 0%, #8EC5FC 100%)'
                bg='linear-gradient(90deg, #005BEA 8.33%, #00C6FB 83.85%);'
            >
                <Box display='flex'>
                    <Text color='#FFF' fontFamily='Pretendard-Medium' fontSize='0.875rem'>
                        음원 응원 정보 보러가기
                    </Text>
                    <Box position='absolute' right='1rem'>
                        <FontAwesomeIcon icon={faChevronRight} size='sm' color="#FFF" />
                    </Box>
                </Box>
            </Link>
        </Center>
    );
};

export default Notice;
