import { Flex, Box, Text, Center, VStack, Spacer } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import HistoryListRows from "./HistoryListRows";
import HistoryListGenreRows from "./HistoryListGenreRows";



const HistoryList = () => {
    const buttons = ["음원 전체", "장르 분류"];
    const [activeMenu, setActiveMenu] = React.useState(0);
    const handleMenuClick = (index) => {
        setActiveMenu(index);
    }

    const [songs, setSongs] = React.useState([]);
    const [activeText, setActiveText] = useState("releaseDate");

    const handleSortClick = (text) => {
        setActiveText(text);
    };

    useEffect(() => {
        const minuteStr = new Date().toISOString().slice(0, 16).replace(/-/g, "").replace(/:/g, "");
        fetch(`https://cdn.hero-chart.kr/artist/hero/songs_v5.json?v=${minuteStr}`)
            .then(res => res.json())
            .then(data => {
                let sortedSongs = Object.entries(data)
                    .map(([, song]) => song)
                    .filter(song => !song.title.endsWith("(MR)") && !song.title.endsWith(".)") && song.release.date !== "20200504" && song.title !== "엘리베이터");

                // Sorting based on activeText
                if (activeText === "releaseDate") {
                    sortedSongs.sort((song1, song2) => song2.release.date.localeCompare(song1.release.date));
                } else if (activeText === "alphabetical") {
                    sortedSongs.sort((song1, song2) => song1.title.localeCompare(song2.title));
                }

                setSongs(sortedSongs);
            });
    }, [activeText]);



    return (
        <Box w="100%" pt='1.69rem'>
            <VStack spacing='0'>
                <Box minW="20.5625rem" maxW="21.5625rem" mb='0.56rem'>
                    <Flex>
                        {buttons.map((button, index) => (
                            <Box
                                px='0.70rem'
                                py='0.25rem'
                                fontFamily={activeMenu === index ? "Pretendard-Medium" : "Pretendard-Regular"}
                                fontWeight='500'
                                borderRadius='1.5rem'
                                // boxShadow={activeMenu === index ? '0px 4px 4px 0px rgba(0, 0, 0, 0.15)' : "none"}
                                // bg='linear-gradient(90deg, #1F3F72 0%, #005BEA 100%);'
                                bg={activeMenu === index ? 'linear-gradient(90deg, #1F3F72 0%, #005BEA 100%);' : '#FFFFFF'}
                                onClick={() => handleMenuClick(index)}
                                key={index}
                                ml='0.2rem'
                            >
                                <Text
                                    color={activeMenu === index ? "#FFFFFF" : "#000000"}
                                >
                                    {button}
                                </Text>
                            </Box>
                        ))}
                    </Flex>
                    <Flex>
                        <Box>
                            <Text
                                fontSize='1rem' fontFamily='Pretendard-Black' lineHeight='1.5rem'
                                bgGradient="linear-gradient(90deg, #1E3C72 0%, #1E3C72 1%, #2A5298 100%)"
                                bgClip="text"
                                // _webkitBackgroundClip="text"
                                color="transparent"
                            >
                                {/* HERO HISTORY */}

                            </Text>
                        </Box>
                        <Spacer />
                        <Center>
                            <Box onClick={() => handleSortClick("releaseDate")}>
                                <Text
                                    color={activeText === "releaseDate" ? "#1E3C72" : "#979797"}
                                    fontFamily={activeText === "releaseDate" ? "Pretendard-SemiBold" : "Pretendard-Medium"}
                                    fontSize="0.8125rem"
                                >
                                    발매일순
                                </Text>
                            </Box>
                        </Center>
                        <Center>
                            <Box onClick={() => handleSortClick("alphabetical")} ml="0.31rem">
                                <Text
                                    color={activeText === "alphabetical" ? "#1E3C72" : "#979797"}
                                    fontFamily={activeText === "alphabetical" ? "Pretendard-SemiBold" : "Pretendard-Medium"}
                                    fontSize="0.8125rem"
                                >
                                    가나다순
                                </Text>
                            </Box>
                        </Center>
                    </Flex>
                </Box>
                {/* <Box><HistoryListRows songs={songs} /></Box> */}
                <Box>
                    {activeMenu === 0 ? <HistoryListRows songs={songs} /> : <HistoryListGenreRows songs={songs} />}
                </Box>
            </VStack>
        </Box >
    )
}

export default HistoryList;