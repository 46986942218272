import React from 'react'
import cx from 'classnames'
import styles from './styles.module.css'
import PropTypes from 'prop-types'

const Day = ({ monthDay, active, onChange, date, disabled }) => {
  const className = cx(styles.day, {
    [styles.active]: active,
    [styles.disabled]: disabled
  })
  const onClick = (e) => {
    e.preventDefault()
    if (!disabled && typeof onChange === 'function') onChange(date)
  }
  const style = disabled ? { color: '#BDBDBD', pointerEvents: 'none' } : { color: 'inherit' }
  return (
    <div onClick={onClick} className={className} style={style}>
      {monthDay}
    </div>
  )
}

Day.propTypes = {
  onChange: PropTypes.func
}

export default Day
