import { GridItem, Flex, Box, Text, Card, CardBody, Grid, Image } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "@chakra-ui/react";



const HistoryListGenreRows = (songs) => {
    const navigate = useNavigate();

    const handleAppLinkClick = (melon_id) => (e) => {
        e.preventDefault();
        const appUrl = `melonapp://play?ctype=1&menuid=1000000026&cid=${melon_id}`;
        const webUrl = `https://www.melon.com/song/detail.htm?songId=${melon_id}`;

        // 앱으로 이동 시도
        window.location = appUrl;

        // 2초 후에 웹 URL로 리디렉션 시도
        setTimeout(() => {
            window.location = webUrl;
        }, 2000);

    };
    const handleClick = (target, songId) => () => {
        console.log(target, songId);
        navigate("/history/" + target + "/" + songId);
    }

    songs = songs.songs;
    // Genre sorting based on the song count
    const genreOrder = {
        'ELECTRONICA': 1,
        'JAZZ': 1.1,
        'DANCE': 2,
        'HIPHOP': 2.1,
        'R&B': 2.2,
        'POP': 4,
        'ROCK': 10,
        'FOLK': 12,
        'ADULT CONTEMPORARY': 16,
        'BALLAD': 38,
        'TROT': 68
    };

    // 장르별로 데이터 그룹화
    const songsByGenre = Object.entries(songs).reduce((acc, [key, song]) => {
        if (!key.endsWith(")") && song.title !== undefined) {
            acc[song.genre] = acc[song.genre] || [];
            acc[song.genre].push(song);
        }
        return acc;
    }, {});

    // Sort genres based on the predefined order
    const sortedGenres = Object.keys(songsByGenre).sort((a, b) => {
        return genreOrder[a] - genreOrder[b];
    });


    return (
        <Box mb="1rem">
            {sortedGenres.map((genre) => (
            // {Object.entries(songsByGenre).map(([genre, genreSongs]) => (
                <Box key={genre}>
                    <Box mb="0.32rem" mt="1.5rem">
                        <Text fontFamily="Pretendard-SemiBold" fontSize="1rem">{genre}</Text>
                    </Box>
                    {
                        Object.entries(songsByGenre[genre]).map(([key, song]) => (
                            (!key.endsWith(")") && song.title !== undefined) &&
                            (<Card
                                mb='0.25rem' mt='0.25rem' minW="20.5625rem" maxW="21.5625rem" h="7.5rem"
                                boxShadow='0px 1px 4px 0px rgba(0, 0, 0, 0.25)'
                                key={key + "CARD"}
                            >
                                <CardBody CardBody p='0' h='7.5rem'>
                                    <Grid
                                        templateColumns={'13rem auto'}
                                        gap='0' alignItems='center' h='7.5rem'>
                                        <GridItem w='11.5rem' colSpan={1} ml='0.94rem' >
                                            <Box
                                                alignItems="center"
                                                justifyContent={"flex-start"}
                                            >
                                                <Text key={key} fontSize="0.875rem" fontFamily="Pretendard-SemiBold">{song.title}</Text>
                                                <Text key={key + "1"} fontSize="0.8125rem" color="#979797" fontFamily="Pretendard-Medium">
                                                    {song.genre}
                                                </Text>
                                                <Text key={key + "2"} fontSize="0.8125rem" color="#979797" fontFamily="Pretendard-Medium">
                                                    {song.album_type}
                                                </Text>
                                                <Text key={key + "3"} fontSize="0.8125rem" color="#979797" fontFamily="Pretendard-Medium">
                                                    {song.release.y}.{song.release.m}.{song.release.d}
                                                </Text>
                                            </Box>
                                        </GridItem>

                                        <GridItem w='4rem' colSpan={1} ml='0.125rem'>
                                            <Box>
                                                <Box mb="0.6rem">
                                                    <Box mb="0.06rem">
                                                        <Text
                                                            color="#979797"
                                                            fontFamily={"Pretendard-SemiBold"}
                                                            fontSize={"0.625rem"}
                                                        >
                                                            PLAY
                                                        </Text>
                                                    </Box>
                                                    <Flex>
                                                        <Box mr="0.38rem">
                                                            <Link href="#" onClick={(e) => handleAppLinkClick(song.melon_id)(e)}>
                                                                <Box
                                                                    borderRadius="2rem"
                                                                    bg="#FFFFFF"
                                                                    boxShadow="0px 4px 4px 0px rgba(0, 0, 0, 0.15)"
                                                                    padding="0"
                                                                    w="1.75rem"
                                                                    h="1.75rem"
                                                                >
                                                                    <Flex
                                                                        alignItems="center"
                                                                        justifyContent="center"
                                                                        w="100%"
                                                                        h="100%"
                                                                    >
                                                                        <Box>
                                                                            <Image src={`/melon_play.png`} w="1.1875rem" h="1.1875rem" />
                                                                        </Box>
                                                                    </Flex>
                                                                </Box>
                                                            </Link>
                                                        </Box>
                                                        {song.yt_music_link && (
                                                            <Box mr="0.38rem">
                                                                <Link href={song.yt_music_link} isExternal>
                                                                    <Box
                                                                        borderRadius="2rem"
                                                                        bg="#FFFFFF"
                                                                        boxShadow="0px 4px 4px 0px rgba(0, 0, 0, 0.15)"
                                                                        padding="0"
                                                                        w="1.75rem"
                                                                        h="1.75rem"
                                                                    >
                                                                        <Flex alignItems="center" justifyContent="center" w="100%" h="100%">
                                                                            <Box>
                                                                                <Image src={`/youtube_music.png`} w="1.1875rem" h="1.1875rem" />
                                                                            </Box>
                                                                        </Flex>
                                                                    </Box>
                                                                </Link>
                                                            </Box>
                                                        )}
                                                        {song.yt_mv_link && (
                                                            <Box>
                                                                <Link href={song.yt_mv_link} isExternal>
                                                                    <Box
                                                                        borderRadius="2rem"
                                                                        bg="#FFFFFF"
                                                                        boxShadow="0px 4px 4px 0px rgba(0, 0, 0, 0.15)"
                                                                        padding="0"
                                                                        w="1.75rem"
                                                                        h="1.75rem"
                                                                    >
                                                                        <Flex alignItems="center" justifyContent="center" w="100%" h="100%">
                                                                            <Box>
                                                                                <Image src={`/youtube_video.png`} w="1.1875rem" h="1.1875rem" />
                                                                            </Box>
                                                                        </Flex>
                                                                    </Box>
                                                                </Link>
                                                            </Box>
                                                        )}
                                                    </Flex>
                                                </Box>
                                                <Box>
                                                    <Box mb="0.06rem">
                                                        <Text
                                                            color="#979797"
                                                            fontFamily={"Pretendard-SemiBold"}
                                                            fontSize={"0.625rem"}
                                                        >
                                                            RECORD
                                                        </Text>
                                                    </Box>
                                                    <Flex>
                                                        <Box mr="0.38rem">
                                                            {
                                                                song.has_melon_history ?
                                                                    (<Box key={key + "2"}
                                                                        onClick={handleClick("melon", song.id)}
                                                                        borderRadius="2rem"
                                                                        bg="#FFFFFF"
                                                                        boxShadow="0px 4px 4px 0px rgba(0, 0, 0, 0.15)"
                                                                        padding="0.40rem 0.37rem 0.56rem 0.37rem"
                                                                        w="2.875rem"
                                                                        h="1.75rem"
                                                                    >
                                                                        <Flex alignItems="center" justifyContent="center" w="100%" h="100%">
                                                                            <Image src={`/melon_icon.png`} />
                                                                        </Flex>
                                                                    </Box>) :
                                                                    (<Box h="1.75rem"></Box>)
                                                            }
                                                        </Box>
                                                        <Box mr="0.38rem">
                                                            {
                                                                song.has_circle_history ?
                                                                    (
                                                                        <Box key={key + "4"}
                                                                            onClick={handleClick("circle", song.id)}
                                                                            borderRadius="2rem"
                                                                            bg="#FFFFFF"
                                                                            boxShadow="0px 4px 4px 0px rgba(0, 0, 0, 0.15)"
                                                                            padding="0.3em 0.56rem 0.1rem 0.56rem"
                                                                            w="2.875rem"
                                                                            h="1.75rem"
                                                                        >
                                                                            <Flex alignItems="center" justifyContent="center" w="100%" h="100%">
                                                                                <Image src={`/circle_icon.png`} />
                                                                            </Flex>
                                                                        </Box>

                                                                    )
                                                                    : (<></>)
                                                            }
                                                        </Box>
                                                    </Flex>
                                                </Box>
                                            </Box>
                                        </GridItem>
                                    </Grid>
                                </CardBody>
                            </Card>
                            )
                        ))
                    }
                </Box>
            ))}
        </Box>
    )
}

export default HistoryListGenreRows;
