
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import PropTypes from 'prop-types'
import { Box, Text, Flex, Spacer } from '@chakra-ui/react';

const TimePicker = (props) => {
  const withProps = () => {
    const { value } = props;
    if (!value) return { hour: 0, minute: 0 }
    const date = new Date(value);
    let hour = date.getHours();
    // if (hour >= 2 && hour <= 6) {
    //   hour = 1;
    // }
    const minute = 0;
    return { hour, minute };
  }
  const increaseHour = () => {
    const date = new Date(props.value)
    date.setHours(date.getHours() + 1, date.getMinutes(), 0)
    if (typeof props.onChange === 'function') props.onChange(date)
  }

  const decreaseHour = () => {
    const date = new Date(props.value)
    date.setHours(date.getHours() - 1, date.getMinutes(), 0)
    if (typeof props.onChange === 'function') props.onChange(date)
  }

  const { hour } = withProps()
  return (
    <Box display='flex' width='100%' alignItems="center" justifyContent={"center"} borderTop='solid 0.00625rem #BCBCBC' paddingTop='2rem'>
      <Flex w='2.25rem' h='2.25rem' justifyContent='center' alignItems='center' onClick={decreaseHour}>
        <FontAwesomeIcon icon={faChevronDown} size='sm' color='#454545'/>
      </Flex>
      <Flex 
        w='11.75rem' h='2.4375rem' alignItems="center"  boxShadow='0px 0px 20px 4px rgba(191, 191, 191, 0.25)'
        borderRadius='0.75rem' margin='0 1.4rem' p='0.625rem 2.5rem'
      >
          <Text >{`0${hour}`.slice(-2)}</Text>
          <Spacer />
          <Text>:</Text>
          <Spacer />
          <Text >00</Text>
      </Flex>
      <Flex w='2.25rem' h='2.25rem' justifyContent='center' alignItems='center' onClick={increaseHour}>
        <FontAwesomeIcon icon={faChevronUp} size='sm' color='#454545' />
      </Flex>
    </Box>
  )
}

TimePicker.propTypes = {
  value: PropTypes.instanceOf(Date),
  onChange: PropTypes.func
}

export default React.memo(TimePicker)
