import React, { createContext, useState, useEffect } from 'react';

// Context 생성
export const SongsContext = createContext();

export const SongsProvider = ({ children }) => {
  const [songs, setSongs] = useState([]);

  useEffect(() => {
    fetch('https://cdn.hero-chart.kr/artist/hero/songs_v5.json')
      .then(response => response.json())
      .then(data => setSongs(data))
      .catch(error => console.error(error));
  }, []);

  return (
    <SongsContext.Provider value={songs}>
      {children}
    </SongsContext.Provider>
  );
};
