import React from "react";
import { Box, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";

export const ChartMenu = ({ activeMenu }) => {

    const menuItems = [
        { name: "홈", link: "/" },
        { name: "실시간", link: "/realtime" },
        { name: "일간", link: "/daily" },
        { name: "주간", link: "/weekly" },
        { name: "월간", link: "/monthly" },
        { name: "글로벌", link: "/global" },
    ];

    return (
        <Box
            ml='0.5rem'
            maxW='100%'
            css={{
                '&::-webkit-scrollbar': {
                    display: 'none'
                },
                'msOverflowStyle': 'none',
                'scrollbarWidth': 'none'
                // '-ms-overflow-style': 'none',
                // 'scrollbar-width': 'none'
            }}
            display="flex" overflowX="auto" whiteSpace="nowrap" padding={'0.6rem 0.25rem'}>
            {menuItems.map((item, index) => (
                <Box key={index}>
                    <Link to={item.link}>
                        <Box
                            borderRadius='1.5rem'
                            padding='0.25rem 0.75rem'
                            margin='0.0rem 0.1rem'
                            // w="3.5rem"
                            bg={activeMenu === index ? "linear-gradient(90deg, #1F3F72 0%, #005BEA 100%)" : "none"}
                        >
                            <Text
                                color={activeMenu === index ? "#FFFFFF" : "#000000"}
                                fontSize='1rem'
                                fontFamily={activeMenu === index ? "Pretendard-Medium" : "Pretendard-Regular"}
                            >{item.name}</Text>
                        </Box>
                    </Link>
                </Box>
            ))}
        </Box>
    );
};
