import React from 'react';
import { Box, VStack, Text, Flex, Image, HStack } from '@chakra-ui/react';
import { RankRow } from './RankRow';


export const RankList = ({ chartName, siteChartName, rankData, timeUnit, chartDateInfo }) => {
    const chartDate = new Date(chartDateInfo);
    const yearStr = chartDate.getFullYear().toString();
    const monthStr = (chartDate.getMonth() + 1).toString().padStart(2, "0");
    const dayStr = chartDate.getDate().toString().padStart(2, "0");
    const hourStr = chartDate.getHours().toString().padStart(2, "0");

    let infoStr = '';
    if (timeUnit === 'H') {
        infoStr = `${monthStr}.${dayStr} ${hourStr}:00`;
    } else if (timeUnit === 'D') {
        infoStr = `${yearStr}.${monthStr}.${dayStr}`;
    } else if (timeUnit === 'W') {
        const nextWeekDate = new Date(chartDate);
        nextWeekDate.setDate(chartDate.getDate() + 6);
        const nextWeekYearStr = nextWeekDate.getFullYear().toString();
        const nextWeekMonthStr = (nextWeekDate.getMonth() + 1).toString().padStart(2, "0");
        const nextWeekDayStr = nextWeekDate.getDate().toString().padStart(2, "0");

        infoStr = `${yearStr}.${monthStr}.${dayStr} ~ ${nextWeekYearStr}.${nextWeekMonthStr}.${nextWeekDayStr}`;
    } else if (timeUnit === 'M') {
        infoStr = `${yearStr}.${monthStr}`;
    }

    // "MELON" in siteChartName
    const isMelon = siteChartName.includes("멜론");
    const isYoutube = siteChartName.includes("유튜브");

    return (

        <VStack spacing={0} pb='1.25rem'>
            <Box minW="20.5625rem" maxW="21.5625rem" m="1.62rem 0 0.56rem 0">
                <HStack>
                    <Text
                        fontSize='1rem' fontFamily='Pretendard-Black' lineHeight='1.5rem'
                        bgGradient="linear-gradient(90deg, #1E3C72 0%, #1E3C72 1%, #2A5298 100%)"
                        bgClip="text"
                        // _webkitBackgroundClip="text"
                        color="transparent"

                    >{siteChartName}</Text>
                    <Text
                        color='#8A8A8A' fontSize='0.875rem' lineHeight='1.125rem'
                    >
                        {infoStr}

                    </Text>
                </HStack>
            </Box>
            {
                rankData.length > 0 &&
                rankData.map((rankRow, index) => (
                    <RankRow
                        key={index}
                        songTitle={rankRow.title}
                        rank={rankRow.rank}
                        rankVariation={rankRow.rank_variation}
                        isNew={rankRow.is_new}
                        isMelon={isMelon}
                        isYoutube={isYoutube}
                        additionalInfo={rankRow.additional_fields}
                        siteChartName={siteChartName}
                    />
                ))
            }
            {
                rankData.length === 0 &&
                <Flex
                    minW="20.5625rem"
                    maxW="21.5625rem"
                    h="3.75rem"
                    bg='linear-gradient(90deg, #F3E7E9 0%, #E3EEFF 99%, #E3EEFF 100%)'
                    borderRadius='0.5rem'
                    justifyContent={'center'}
                    alignItems={'center'}
                    display={'flex'}
                >
                    {/* <Text fontFamily='Luckiest Guy' fontSize='1.5rem' fontWeight='400' color='#000'> Let's try a little more :(</Text> */}
                    <Image src="/no_entry.svg" />
                </Flex>
            }

        </VStack>
    );
};

