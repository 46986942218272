import { Box, Text, VStack } from "@chakra-ui/react";
import React from "react";

export const Footer = () => {
    return (
        <Box w='100%' h='4.625rem' display="flex" alignItems="center" justifyContent="center" bg="#1F3F72">
            <VStack spacing='0'>
                <Text fontSize='0.625rem' fontFamily='Pretendard-ExtraLight' lineHeight='1rem' color='#FFFFFF' textAlign='center'>HERO CHART by HEROISM</Text>
                <Text fontSize='0.625rem' fontFamily='Pretendard-ExtraLight' lineHeight='1rem' color='#FFFFFF' textAlign='center'>CONTACT: herochart0616@gmail.com</Text>
            </VStack>
        </Box>
    );
};