import { AspectRatio, Box, Text, Center, VStack, Button } from "@chakra-ui/react";

import React from "react";
import { TopNavBar } from "../components/TopNavBar";
import HistoryList from "../components/HistoryList";


const HeroHistory = () => {
    const disableCopy = (e) => {
        e.preventDefault();
    }
    return (
        <Box onCopy={disableCopy}>
            <TopNavBar />
            <Box
                w='100%'
                maxW='100%'
                overflowX='hidden'
            >
                <Box bg='linear-gradient(90deg, #FDFBFB 0%, #EBEDEE 100%)' minH='90vh'>
                    <HistoryList />
                </Box>
            </Box>
        </Box >
    )
}

export default HeroHistory;