import { MainPage } from '../components/MainPage';


const Realtime = () => {
    const activeMenu = 1;
    const siteMenus = ["멜론", "지니", "FLO", "벅스"];
    const chartMap = {
        "멜론": ["멜론 TOP100", "멜론 HOT100 (100일)", "멜론 실시간차트"],
        "지니": ["지니 실시간차트"],
        "FLO": ["FLO 24시간 차트"],
        "벅스": ["벅스 실시간차트"]
    }

    return (
        <MainPage siteMenus={siteMenus} chartMap={chartMap} activeMenu={activeMenu} pickerType="time"/>
    );
}

export default Realtime;
