import { MainPage } from '../components/MainPage';


const Monthly = () => {
    const activeMenu = 4;
    const siteMenus = ["멜론", "지니" ];
    const chartMap = {
        "멜론": ["멜론 월간차트"],
        "지니": ["지니 월간차트"],
    }

    return (
        <MainPage siteMenus={siteMenus} chartMap={chartMap} activeMenu={activeMenu} pickerType="month"/>
    );
}


export default Monthly;
