import React, { useMemo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faChevronLeft,
    faChevronRight,
} from '@fortawesome/free-solid-svg-icons'
import WeekDays from '../WeekDays'
import Days from '../Days'
import PropTypes from 'prop-types'
import styles from './styles.module.css'
import TimePicker from '../TimePicker'
import { Box, Button, Text } from '@chakra-ui/react'

const setupFormats = (locale) => {
    const date = new Date(1970, 2, 1)
    const dayFormat = new Intl.DateTimeFormat(locale, {
        weekday: 'short'
    })
    const monthFormat = new Intl.DateTimeFormat(locale, {
        month: 'short'
    })
    const weekdays = []
    const months = []
    for (let i = 8; i < 15; i++) {
        date.setDate(i);
        weekdays.push(dayFormat.format(date))
    }
    for (let i = 0; i < 12; i++) {
        date.setMonth(i)
        months.push(monthFormat.format(date))
    }
    return {
        weekdays,
        months
    }
}
const ControllerDatePicker = (props) => {
    const { weekdays, months } = useMemo(() => setupFormats('ko'), [])
    const withprops = () => {
        const {
            value,
            active,
            setMonth,
            selectedMonth,
            onSelect,
            pickerType,
            closeFunction
        } = props
        const date = new Date(selectedMonth || value)
        const year = date.getFullYear()
        const month = date.getMonth()
        return {
            selectedMonth: new Date(year, month, 1),
            year,
            month,
            active,
            setMonth,
            onSelect,
            pickerType,
            closeFunction
        }
    }
    const {
        selectedMonth,
        year,
        month,
        active,
        setMonth,
        onSelect,
        pickerType,
        closeFunction
    } = withprops()
    const prev = () => {
        const year = selectedMonth.getFullYear()
        const month = selectedMonth.getMonth()
        setMonth(new Date(year, month - 1, 1, 0, 0, 0, 0))
        props.onSelect(new Date(year, month - 1, 1, 0, 0, 0, 0))
    }
    const next = () => {
        const year = selectedMonth.getFullYear()
        const month = selectedMonth.getMonth()
        setMonth(new Date(year, month + 1, 1, 0, 0, 0, 0))
        props.onSelect(new Date(year, month + 1, 1, 0, 0, 0, 0))
    }
    // const prevYear = () => {
    //     const year = selectedMonth.getFullYear()
    //     const month = selectedMonth.getMonth()
    //     setMonth(new Date(year, month - 12, 1, 0, 0, 0, 0))
    // }
    // const nextYear = () => {
    //     const year = selectedMonth.getFullYear()
    //     const month = selectedMonth.getMonth()
    //     setMonth(new Date(year, month + 12, 1, 0, 0, 0, 0))
    // }

    const isDisabled = props.value > new Date() || props.value < new Date('2023-10-18');

    return (
        <div className={styles.pickerWrapper}>
            <div className={styles.controls}>
                <div className={styles.buttonGroupLeft}>
                    {/* <a className={styles.button}>
                        <FontAwesomeIcon icon={faAngleDoubleLeft} onClick={prevYear} />
                    </a> */}
                    <a className={styles.button} onClick={prev} w='1.5rem' h='1.5rem'>
                        <FontAwesomeIcon icon={faChevronLeft} color='#FFF' />
                    </a>
                </div>
                <div className={styles.header}>
                    {year}년 {months[month]}
                </div>
                <div className={styles.buttonGroupRight}>
                    <a className={styles.button} onClick={next} w='1.5rem' h='1.5rem'>
                        <FontAwesomeIcon icon={faChevronRight} color='#FFF' />
                    </a>
                    {/* <a className={styles.button}>
                        <FontAwesomeIcon icon={faAngleDoubleRight} onClick={nextYear} />
                    </a> */}
                </div>
            </div>
            {["days", "time"].includes(pickerType) && <WeekDays weekdays={weekdays} />}
            {["days", "time"].includes(pickerType) && <Days
                year={year}
                month={month}
                active={active}
                onChange={onSelect}
                value={props.value}
            />}
            {["time"].includes(pickerType) && <TimePicker value={props.value} onChange={onSelect} />}
            <Box display='flex' alignItems="center" justifyContent={"flex-end"} mt="1rem">
                {isDisabled ? (
                    <Button
                        w='5.6875rem' h='1.9375rem' bg='#BDBDBD' onClick={null}>
                        <Text color='#FFF' fontFamily='Pretendard-ExtraLight' fontSize='0.875rem'>선택 완료</Text>
                    </Button>
                ) : (
                    <Button
                        w='5.6875rem' h='1.9375rem' bg='linear-gradient(90deg, #1E3C72 0%, #1E3C72 1%, #2A5298 100%)'
                        _hover={{ bg: 'linear-gradient(90deg, #1E3C72 0%, #1E3C72 1%, #2A5298 100%)' }}
                        onClick={closeFunction}>
                        <Text color='#FFF' fontFamily='Pretendard-ExtraLight' fontSize='0.875rem'>선택 완료</Text>
                    </Button>
                )}
            </Box>
        </div>
    )
}

ControllerDatePicker.propTypes = {
    value: PropTypes.instanceOf(Date),
    selectedMonth: PropTypes.instanceOf(Date),
    setMonth: PropTypes.func,
    active: PropTypes.func,
    onSelect: PropTypes.func,
    pickerType: PropTypes.string,
    closeFunction: PropTypes.func
}

export default ControllerDatePicker
