import React from 'react';

import { MainPage } from '../components/MainPage';


const Daily = () => {
    const activeMenu = 2;
    const siteMenus = ["멜론", "지니", "바이브", "벅스", "스포티파이", "써클"];
    const chartMap = {
        "멜론": ["멜론 일간차트"],
        "지니": ["지니 일간차트"],
        "바이브": ["바이브 일간차트"],
        "벅스": ["벅스 일간차트"],
        "스포티파이": ["스포티파이 일간차트"],
        "써클": ["써클글로벌 일간차트"]
    }

    return (
        <MainPage siteMenus={siteMenus} chartMap={chartMap} activeMenu={activeMenu} pickerType="days" />
    );
}

export default Daily;