import { MainPage } from '../components/MainPage';


const Weekly = () => {
    const activeMenu = 3;
    const siteMenus = ["멜론", "지니", "벅스", "스포티파이", "써클", "빌보드", "유튜브"];
    const chartMap = {
        "멜론": ["멜론 주간차트"],
        "지니": ["지니 주간차트"],
        "벅스": ["벅스 주간차트"],
        "스포티파이": ["스포티파이 주간차트"],
        "써클": ["써클디지털 주간차트", "써클글로벌 주간차트"],
        "빌보드": ["빌보드 글로벌(-美) 주간차트", "빌보드 로컬 주간차트"],
        "유튜브": ["유튜브뮤직 주간차트", "유튜브 뮤비 주간차트"]
    }

    return (
        <MainPage siteMenus={siteMenus} chartMap={chartMap} activeMenu={activeMenu} pickerType="days"/>
    );
}

export default Weekly;
