import { useState } from "react";
import { DateTimePicker } from "./DateTimePicker";

import { Box, Center } from "@chakra-ui/react";

export const Calendar = (props) => {

    // Default Function. Not modified yet.
    const [selectedDateTime, setSelectedDateTime] = useState(new Date());

    const handleDateTimeChange = (newDateTime) => {
        setSelectedDateTime(newDateTime);
    };


    return (
        <Center>
            <Box mt='2rem'>
                <DateTimePicker
                    handleDateChange={props.handleDateChange}
                    selectedDate={props.selectedDate}
                    placeholder='날짜 선택'
                    value={selectedDateTime}
                    onChange={handleDateTimeChange}
                    pickerType={props.pickerType}
                />
            </Box>
        </Center>
    );
}
