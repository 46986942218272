import React from "react";
import { Box, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";




export const SiteMenu = ({ menus, handleSiteChange }) => {
    const [activeMenu, setActiveMenu] = React.useState(0);

    // const handleMenuClick = (index) => {
    //     setActiveMenu(index);
    //     handleSiteChange(menus[index]);
    // };

    const handleMenuClick = (index, event) => {
        setActiveMenu(index);
        handleSiteChange(menus[index]);

        // DOM element of the clicked menu item
        const element = event.currentTarget;

        // Scroll properties
        const scrollContainer = element.parentElement;
        const scrollContainerWidth = scrollContainer.offsetWidth;
        const elementWidth = element.offsetWidth;
        const elementLeft = element.offsetLeft;
        const elementCenter = elementLeft + elementWidth / 2;

        // Calculate new scroll position
        const newScrollPosition = elementCenter - scrollContainerWidth / 2;

        // Scroll to the new position
        scrollContainer.scrollTo({
            top: 0,
            left: newScrollPosition,
            behavior: 'smooth'
        });
    };

    return (
        <Box
            ml="0.5rem"
            // pl='0.75rem'
            pr='0.75rem'
            pb='0.62rem'
            display="flex" overflowX="auto" whiteSpace="nowrap" maxW="100%"
            css={{
                '&::-webkit-scrollbar': {
                    display: 'none'
                },
                'msOverflowStyle': 'none',
                'scrollbarWidth': 'none'
            }}
        >
            {menus.map((menu, index) => (
                <Link to={`#${menu}`} key={index} onClick={(e) => handleMenuClick(index, e)}>
                    <Box
                        px='0.70rem'
                        py='0.25rem'
                        fontFamily={activeMenu === index ? "Pretendard-Medium" : "Pretendard-Regular"}

                        borderRadius='1.5rem'
                        boxShadow={activeMenu === index ? '0px 4px 4px 0px rgba(0, 0, 0, 0.15)' : "none"}
                    >
                        <Text color={activeMenu === index ? "#1F3F72" : "#8A8A8A"}>
                            {menu}
                        </Text>
                    </Box>
                </Link>
            ))}
        </Box>
    );
};
