import React, { useEffect } from 'react';
import { Box, ChakraProvider } from '@chakra-ui/react';
import { Route, Routes, useLocation, matchPath } from 'react-router-dom';
import Home from './pages/Home';
import Realtime from './pages/Realtime';
import Daily from './pages/Daily';
import Weekly from './pages/Weekly';
import Monthly from './pages/Monthly';
import Global from './pages/Global';
import { Footer } from './components/Footer';

import { logEvent } from "firebase/analytics";
import { analytics } from "./firebaseConfig";
import HeroHistory from './pages/HeroHistory';
import MelonHistory from './pages/MelonHistory';
import CircleHistory from './pages/CircleHistory';
import { SongsProvider } from './components/SongsContext';


const pages = [
  { path: '/', component: <Home />, title: "홈" },
  { path: '/realtime', component: <Realtime />, title: "실시간" },
  { path: '/daily', component: <Daily />, title: "일간" },
  { path: '/weekly', component: <Weekly />, title: "주간" },
  { path: '/monthly', component: <Monthly />, title: "월간" },
  { path: '/global', component: <Global />, title: "글로벌" },
  { path: '/history/melon/:songId', component: <MelonHistory />, title: "멜론 히스토리" },
  { path: '/history/circle/:songId', component: <CircleHistory />, title: "써클 히스토리" },
  { path: '/history', component: <HeroHistory />, title: "히스토리" },
]

function findPageByPath(path, pages) {
  // 정적 경로를 먼저 확인
  let page = pages.find(page => page.path === path);
  if (page) return page;

  // 정적 경로가 아닌 경우, 동적 세그먼트를 확인
  for (let page of pages) {
    // 동적 세그먼트를 포함하는 경로인지 확인
    const match = matchPath({
      path: page.path,
      end: false, // 부분적으로 일치하는 것을 허용
    }, path);

    // 일치하는 경우 해당 페이지 객체 반환
    if (match) return page;
  }

  // 일치하는 페이지가 없는 경우
  return null;
}

function App() {
  const location = useLocation();
  const title = "Hero Chart";

  useEffect(() => {
    const currentPage = findPageByPath(location.pathname, pages);
    if (!currentPage) return;
    document.title = title + " - " + currentPage.title;
    if (process.env.NODE_ENV === 'production') {
      const currentPage = location.pathname + location.search;
      logEvent(analytics, 'page_view', {
        page_path: currentPage,
      });
    }
  }, [location]);

  const disableCopy = (e) => {
    e.preventDefault();
  }

  return (
    <ChakraProvider>
      <Box onCopy={disableCopy}>
        <SongsProvider>
          <Routes>
            {pages.map((page, index) => (
              <Route key={index} path={page.path} element={page.component}></Route>
            ))}
          </Routes>
        </SongsProvider>
        <Footer />
      </Box>
    </ChakraProvider>
  );
}

export default App;
