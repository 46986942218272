import React from 'react';

import { MainPage } from '../components/MainPage';


function Home() {
    const activeMenu = 0;
    const siteMenus = ["멜론"];
    const chartMap = {
        "멜론": ["멜론 TOP100"],
    }

    return (
        <MainPage siteMenus={siteMenus} chartMap={chartMap} activeMenu={activeMenu} pickerType="time" home />
    );
}

export default Home;
