// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDIG8ZoLEJp7sl9srJQgF-nuu79rEZ_o4Q",
  authDomain: "hero-chart.firebaseapp.com",
  projectId: "hero-chart",
  storageBucket: "hero-chart.appspot.com",
  messagingSenderId: "366062963720",
  appId: "1:366062963720:web:94d90f06c4687c6f4a55bd",
  measurementId: "G-L7SPQEY9G9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
