import { AspectRatio, Box, Text, Center, VStack, Button } from "@chakra-ui/react";

import React from "react";
import { useNavigate } from 'react-router-dom';


const HeroHistoryButton = () => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate("/history");
    }

    return (
        <VStack spacing={0} pb='0.0rem' pt="0.75rem">
            <Button
                onClick={handleClick}
                borderRadius="0.3125rem"
                bg="#FFFFFF"
                boxShadow="0px 4px 4px 0px rgba(0, 0, 0, 0.15)"
                padding="0.1rem 1.2rem"
                w="9.0rem"
                h="2rem"
            >
                <Text
                    fontSize="0.8725rem"
                    fontFamily="Pretendard-Black"
                    color="#1F3F72">
                    HERO HISTORY  →
                </Text>
            </Button>
        </VStack>
    )
}

export default HeroHistoryButton;